<template>
  <div class="row">
    <div class="col-sm-12">

      <carrousel :folder="this.slug"></carrousel>
    </div>
  </div>
</template>
<script>
import Carrousel from "@/components/Carrousel";
export default {
  name: 'PhotoViewer',
  props: ['slug'],
  components: {Carrousel},
  data: () => {
    return {

    };
  },

};
</script>
