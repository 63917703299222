<template>
  <div
    class="centered"
    style="min-height: 310px; width: 100%; max-width: 1400px"
    :style="{
      backgroundImage: `linear-gradient(to bottom, ${this.color}, #050404)`,
    }"
  >
    <div>
      <div class="text-center">
        <div style="max-width: 300px; height: 300px" class="centered">
          <img
            :alt="this.caption"
            class="text-center"
            style="margin-top: 12px"
            width="300px"
            height="300px"
            :key="this.image"
            v-lazy="
              this.image + '?w=300&h=300&fit=clamp&fill=blur&auto=enhance'
            "
          />
        </div>
        <p
          style="font-size: 11px; margin-top: 30px"
          v-html="this.imageCaption"
          v-if="this.imageCaption"
        ></p>
      </div>
    </div>
    <div
      class="row carrousel-images"
      style="margin-right: auto; left: 0; right: 0"
    >
      <div v-for="(photo, idx) in this.images" class="" :key="idx">
        <img
          style="border-radius: 0px; cursor: pointer"
          class="carrousel-image"
          :class="photo === image ? 'active' : ''"
          height="60px"
          width="60px"
          :alt="imageCaptions[idx] || 'carrousel image'"
          v-lazy="photo + '?w=60&h=60&fit=clamp&fill=blur&auto=format'"
          @click="viewImage(idx, photo)"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { MEDIA_BASE_URL } from '@/common/request';

export default {
  name: 'Carrousel',
  created: function () {
    if (this.folder) {
      fetch(
        encodeURI(
          `${MEDIA_BASE_URL}?props=imgix_url,metadata&read_key=7pMG22sp4oEfuIRswb3c9kfsxlfxa9vxrGAOUBJttBwiP4FkGQ&query={"folder": "${this.folder}"}`
        )
      )
        .then((response) => {
          response.json().then((r) => {
            let sorted = [...r.media].sort((x, y) =>
              (x?.metadata?.order || 100) > (y?.metadata?.order || 99) ? 1 : -1
            );
            this.images = sorted.map((x) => x.imgix_url);
            this._captions = sorted.map((x) =>
              x.metadata ? x.metadata.caption : '- -'
            );
            this.viewImage(0, this.images[0]);
          });
        })
        .catch((x) => {
          console.error(x);
        });
    } else {
      this.images = this.photos;
    }
  },
  data: () => {
    return {
      loaded: false,
      largeImage: null,
      imageCaption: null,
      _captions: ['default'],
      images: null,
      faqsImg:
        'https://imgix.cosmicjs.com/3c6580f0-6c2c-11ee-ae6f-f1c9a9c95b9c-image.png',
    };
  },
  methods: {
    viewImage(idx, image) {
      this.largeImage = image;
      this.imageCaption = this.captions[idx]
        ? this.captions[idx]
        : this.imageCaptions[idx];
    },
  },
  computed: {
    image: {
      get() {
        return (
          this.largeImage ||
          (this.images
            ? this.images[0]
            : 'https://imgix.cosmicjs.com/3d235160-ccd1-11ee-9ce5-59949019255e-loading.jpeg')
        );
      },
    },
    caption: {
      get() {
        return (
          this.imageCaption ||
          (this._captions ? this._captions[0] : 'tulum party photos')
        );
      },
    },
    imageCaptions: {
      get() {
        return this._captions || [];
      },
    },
  },
  props: {
    photos: {
      type: Array, // striped | hover
      default: () => [''],
    },
    captions: {
      type: Array, // striped | hover
      default: () => [''],
    },
    color: {
      type: String,
      default: null,
    },
    folder: {
      type: String,
      default: null,
    },
  },
};
</script>
